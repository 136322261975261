import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { Container, Table } from "react-bootstrap"


// import BackgroundImage from 'gatsby-background-image'

const pageInfo = () => (
  <Layout>
    <SEO title="Welded Wire Panels" />
    <Container>
      <h1>Welded Wire Panels</h1>
      <ul>
        <li>Deck Rails</li>
        <li>Stairway In-Fill Panels</li>
        <li>Trellises</li>
        <li>Machine & Security Guards</li>
        <li>Stock Sizes Shown Below</li>
      </ul>
      <Table striped bordered responsive className="noWrap">
        <thead>
        <tr>
            <th>Metal</th>
            <th>Mesh Size</th>
            <th>Wire Diameter</th>
            <th>Opening</th>
            <th>Sizes Available</th>
        </tr>
        </thead>
        <tbody>
            <tr>
              <td>
                <ul>
                  <li>Plain Steel</li>
                  <li>Plain Steel</li>
                  <li>Plain Steel</li>
                  <li>Plain Steel</li>
                  <li>Plain Steel</li>
                  <li>Plain Steel</li>
                  <li>Plain Steel</li>
                  <li>Plain Steel</li> 
                </ul>
              </td>
              <td>
                <ul>
                  <li>1″ x 1″</li>
                  <li>2″ x 2″</li>
                  <li>2″ x 2″</li>
                  <li>2″ x 2″</li>
                  <li>3″ x 3″</li>
                  <li>3″ x 3″</li>
                  <li>4″ x 4″</li>
                  <li>4″ x 4″</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>.135″</li>
                  <li>.128″</li>
                  <li>.188″/.192″</li>
                  <li>.250″</li>
                  <li>.188″/.192″</li>
                  <li>.250″</li>
                  <li>.192″</li>
                  <li>.250″</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>.865″</li>
                  <li>1.872″</li>
                  <li>1.812″/1.808″</li>
                  <li>1.75″</li>
                  <li>2.812″/2.808″</li>
                  <li>2.75″</li>
                  <li>3.808″</li>
                  <li>3.75″</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>4′ or 5′ x 10′ sheets</li>
                  <li>4′ or 5′ x 10′ sheets</li>
                  <li>4′ or 5′ x 10′ sheets</li>
                  <li>4′ or 5′ x 10′ sheets</li>
                  <li>4′ or 5′ x 10′ sheets</li>
                  <li>4′ or 5′ x 10′ sheets</li>
                  <li>7′ x 20′ sheets</li>
                  <li>4′ x 10′, 4′ x 12′ or 5′ x 12′</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>Hot Dip Galvanized</li>
                  <li>Pre-Galvanized</li>
                  <li>Pre-Galvanized</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>2″ x 2″</li>
                  <li>2″ x 4″</li>
                  <li>4″ x 4″</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>.135″</li>
                  <li>.192″</li>
                  <li>.225″</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>1.865″</li>
                  <li>1.808″ x 3.808″</li>
                  <li>3.775″</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>5′ x 10′ sheets</li>
                  <li>5′ x 16′ sheets</li>
                  <li>5′ x 16′ sheets</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>Stainless Steel (304)</li>
                  <li>Stainless Steel (304)</li>
                  <li>Stainless Steel (304)</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>1″ x 1″</li>
                  <li>2″ x 2″</li>
                  <li>4″ x 4″</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>.120″</li>
                  <li>.148″</li>
                  <li>.187″</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>.880″</li>
                  <li>1.852″</li>
                  <li>3.813″</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>4′ x 10′ sheets</li>
                  <li>4′ x 10′ sheets</li>
                  <li>4′ x 10′ sheets</li>
                </ul>
              </td>
            </tr>
        </tbody>
    </Table>
    <p>Welded Mesh Panels Come with Smooth Edges</p>
  </Container>
</Layout>
)

export default pageInfo
